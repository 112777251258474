<template>
  <div class="row d-flex flex-row-fluid">
    <!--begin::Aside-->
    <div id="kt_profile_aside" class="flex-row-fluid col-lg-3">
      <!--begin::Profile Card-->
      <div class="card card-custom card-stretch">
        <!--begin::Body-->
        <div class="card-body pt-10">
          <!--begin::UserCard-->
          <UserCard />
          <hr />
          <!--end::UserCard-->
          <!--begin::Nav-->
          <Sidebar :items="itemsSidebar" />
          <!--end::Nav-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Profile Card-->
    </div>
    <!--end::Aside-->
    <!--begin::Content-->
    <!-- <transition name="fade-in-up"> -->
    <router-view />
    <!-- </transition> -->
    <!--end::Content-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import Sidebar from '@/shared/layout/aside/Sidebar';
import UserCard from '@/shared/components/user/UserCard';

export default {
  components: {
    UserCard,
    Sidebar
  },
  data() {
    return {
      itemsSidebar: [
        {
          label: 'Dados Pessoais',
          icon: '/media/svg/icons/General/User.svg',
          href: '/profile/personal',
          parents: '/profile/personal'
        },
        {
          label: 'Endereço',
          icon: '/media/svg/icons/Map/Compass.svg',
          href: '/profile/address',
          parents: '/profile/address'
        },
        {
          label: 'Contato',
          icon: '/media/svg/icons/Devices/iPhone-X.svg',
          href: '/profile/contact',
          parents: '/profile/contact'
        },
        {
          label: 'Dados Bancários',
          icon: '/media/svg/icons/Home/Building.svg',
          href: '/profile/bank',
          parents: '/profile/bank'
        },
        {
          label: 'Segurança',
          icon: '/media/svg/icons/General/Lock.svg',
          href: '/profile/security',
          parents: '/profile/security'
        },
        {
          label: 'Validação de conta',
          icon: '/media/svg/icons/General/Attachment1.svg',
          href: '/profile/documents',
          parents: '/profile/documents'
        }
      ]
    };
  },
  computed: mapGetters(['currentUser', 'currentStatus', 'currentCustomer', 'currentAddress']),
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil', route: 'profile' }, { title: 'Meu Perfil' }]);
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    formatDateGet(value) {
      return $moment(value).format('DD/MM/YYYY');
    }
  }
};
</script>

<style></style>
